<template>
  <FullScreenDialog
    :value="value"
    :title="title"
    @input="$emit('input', $event)"
  >
    <div
      v-for="(data, name) in projects"
      :key="name"
      class="mt-8"
      @click="goToProject(data.url)"
    >
      <v-img
        :src="data.logo"
        height="32"
        position="left"
        contain
      />
      <span
        class="ui-text ui-text_caption ui-kit-color-text-info"
      >
        {{ data.description }}
      </span>
    </div>
  </FullScreenDialog>
</template>

<script>
import FullScreenDialog from 'components/mobile/core/FullScreenDialog.vue'

export default {
  name: 'OurProjectsDialog',
  components: {
    FullScreenDialog,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    projects: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      title: 'Наши проекты',
    }
  },
  methods: {
    goToProject(url) {
      if (typeof url === 'string') {
        window.location.href = url
      }
    },
  },
}
</script>
