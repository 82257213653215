var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app",
    { attrs: { "data-snackbar-notification": "" } },
    [
      _c(
        "v-snackbar",
        _vm._b(
          {
            directives: [
              {
                name: "touch",
                rawName: "v-touch",
                value: {
                  down: _vm.closeSnackbar,
                },
                expression: "{\n      down: closeSnackbar,\n    }",
              },
            ],
            staticClass: "snackbar-notification",
            class: { "snackbar-notification_dialog": _vm.upper },
            attrs: { timeout: _vm.snackbarTimeout, color: "text" },
            scopedSlots: _vm._u(
              [
                _vm.actionText
                  ? {
                      key: "action",
                      fn: function ({ attrs }) {
                        return [
                          _c(
                            "v-btn",
                            _vm._b(
                              {
                                staticClass: "fix-medotvet",
                                attrs: {
                                  color: "button-text-snackbars",
                                  text: "",
                                },
                                on: { click: _vm.handleAction },
                              },
                              "v-btn",
                              attrs,
                              false
                            ),
                            [
                              _vm._v(
                                "\n        " +
                                  _vm._s(_vm.actionText) +
                                  "\n      "
                              ),
                            ]
                          ),
                        ]
                      },
                    }
                  : null,
              ],
              null,
              true
            ),
            model: {
              value: _vm.isActive,
              callback: function ($$v) {
                _vm.isActive = $$v
              },
              expression: "isActive",
            },
          },
          "v-snackbar",
          { ..._vm.snackbarSettings },
          false
        ),
        [
          _c("span", { staticClass: "ui-text ui-text_body-2" }, [
            _vm._v("\n      " + _vm._s(_vm.message) + "\n    "),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }