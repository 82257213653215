import { REGEX } from 'utils/regex'
import getOnlyNumbers from 'utils/withDependences/getOnlyNumbers'

/**
 * @description
 *
 * Функция возвращает объект для валидации поля phone
 *
 * @return { Object } - объект функций с валидацией поля по различным параметрам. Каждая функция принимает обязательное значение поля - value
 * */

const PHONE_CONVERTED_LENGTH = 11

const getPhoneValidationForRussia = () => ({
  regex: val => REGEX.phone.test(val.trim()) || 'Укажите номер в формате +7 9XX XXX XX XX',
  length: val => getOnlyNumbers(val).length === PHONE_CONVERTED_LENGTH || 'Укажите номер в формате +7 9XX XXX XX XX',
})

const getPhoneValidationForYDoc = () => ({
  regex: val => REGEX.universalPhone.test(val.trim()) || 'Номер должен содержать только цифры и иметь длину от 7 до 15 символов',
})

const getPhoneValidation = () => {
  const rules = window.IS_YDOC
    ? getPhoneValidationForYDoc()
    : getPhoneValidationForRussia()

  return {
    required: val => !!val || 'Укажите номер телефона',
    ...rules,
  }
}

export default getPhoneValidation
