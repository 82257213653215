import { getQueryParam } from 'utils'
import { LOGIN_LINKS } from 'constants/links'

export default {
  name: 'ChooseLoginType',
  data() {
    return {
      loginPathMedtochka: '',
      loginPathCabinet: '',
    }
  },
  created() {
    const medtochkaUrl = new URL(LOGIN_LINKS.medtochka)
    const cabinetUrl = new URL(LOGIN_LINKS.profile)
    const queryParamNext = getQueryParam('next')

    if (queryParamNext) {
      medtochkaUrl.searchParams.set('next', queryParamNext)
      cabinetUrl.searchParams.set('next', queryParamNext)
    }

    this.loginPathMedtochka = medtochkaUrl.href
    this.loginPathCabinet = cabinetUrl.href
  },
}
