import scrollLockV2 from 'utils/withDependences/scrollLockV2'
import isTouch from 'utils/is/isTouch'

const body = document.querySelector('body')
const hasTouch = isTouch()

let scrollPosition = 0
let enabledCount = 0 // Количество активных модалок
let disableTimeout = null // Таймер для отложенного disable

function isEnabled() {
  return enabledCount > 0
}

function enable({ usePosition = true } = {}) {
  if (disableTimeout) {
    clearTimeout(disableTimeout) // Отменяем запланированный disable, если открылась новая модалка
    disableTimeout = null
  }

  enabledCount++

  if (enabledCount > 1) {
    return
  }

  if (!hasTouch) {
    document.body.style.paddingRight = `${window.innerWidth - document.documentElement.clientWidth}px`
    document.documentElement.style.overflow = 'hidden'

    return
  }

  scrollPosition = window.pageYOffset

  if (usePosition) {
    body.style.position = 'fixed'
    body.style.top = `-${scrollPosition}px`
    body.style.width = '100%'
  }

  document.documentElement.style.overflow = 'hidden'
}

function disable(delay = 0) {
  if (enabledCount === 0) {
    return
  }

  enabledCount--

  if (enabledCount > 0) {
    return
  }

  if (delay > 0) {
    if (disableTimeout) {
      return
    }

    disableTimeout = setTimeout(() => {
      performDisable()
      disableTimeout = null
    }, delay)
  } else {
    performDisable()
  }
}

function performDisable() {
  if (!hasTouch) {
    document.body.style.paddingRight = ''
    document.documentElement.style.overflow = ''

    return
  }

  body.style.removeProperty('position')
  body.style.removeProperty('top')
  body.style.removeProperty('width')

  document.documentElement.style.overflow = ''

  window.scrollTo(0, scrollPosition)
}

/**
 * todo: необходимо избавиться от текущей утилиты и использовать библиотеку 'body-scroll-lock',
 * т.к. текущая реализация не всегда корректно работает.
 *
 * UPD: 'body-scroll-lock' тоже некорректно работает: на декстопе не пропадает scrollbar,
 * т.к. либа скрывает его на `<body>`, а у нас скорлл работает на `<html>`. Но даже с этим недостатком
 * предпочтительнее использовать данную либу (данная проблемы была вынесена в тех.долг для исправления).
 *
 * */

// todo: после выпиливания pd_fix_body_scroll удалить текущий файл
const scrollLock = window.FEATURE_FLAGS.pd_fix_body_scroll
  ? {
    enable: scrollLockV2.disableScroll,
    disable: scrollLockV2.enableScroll,
    isEnabled: scrollLockV2.isDisabledScroll,
  }
  : {
    enable,
    disable,
    isEnabled,
  }

export default scrollLock
