<template>
  <v-app data-base-dialog>
    <v-dialog
      v-model="isActive"
      :attach="attachSelector"
      :persistent="persistent"
      :max-width="maxWidth"
      :no-click-animation="noClickAnimation"
      hide-overlay
      class="base-dialog"
    >
      <v-card>
        <v-card-title
          class="ui-text ui-text_h6 ui-kit-color-text"
        >
          {{ title }}
        </v-card-title>
        <v-card-text
          class="ui-text ui-text_body-1 ui-kit-color-text-secondary"
        >
          {{ text }}
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            v-if="closeText"
            :color="confirmText ? 'text' : 'primary'"
            text
            @click="handleClose"
          >
            {{ closeText }}
          </v-btn>
          <v-btn
            v-if="confirmText"
            text
            :color="isDanger ? 'secondary' : 'primary'"
            @click="handleConfirm"
          >
            {{ confirmText }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-overlay
      :value="isActive"
      z-index="110"
    />
  </v-app>
</template>

<script>
import { EVENTS as DIALOG_EVENTS, MAX_WIDTH } from 'components/common/core/BaseDialog/constants'

export default {
  name: 'BaseDialog',
  data() {
    return {
      maxWidth: MAX_WIDTH,
      isActive: false,
      isDanger: false,
      persistent: false,
      noClickAnimation: false,
      title: '',
      text: '',
      closeText: '',
      confirmText: '',
      beforeClose: undefined,
      beforeConfirm: undefined,
      attachSelector: undefined,
    }
  },
  created() {
    window.addEventListener(DIALOG_EVENTS.open, e => this.openDialog(e.detail))
    window.addEventListener(DIALOG_EVENTS.close, e => this.closeDialog(e.detail))
  },
  methods: {
    openDialog({
      isDanger,
      persistent,
      noClickAnimation,
      attachSelector,
      title,
      text,
      closeText,
      confirmText,
      beforeClose,
      beforeConfirm,
      maxWidth,
    }) {
      this.isActive = true
      this.isDanger = isDanger
      this.persistent = persistent
      this.noClickAnimation = noClickAnimation
      this.title = title
      this.text = text
      this.closeText = closeText
      this.confirmText = confirmText
      this.beforeClose = beforeClose
      this.beforeConfirm = beforeConfirm
      this.maxWidth = maxWidth || MAX_WIDTH
      this.attachSelector = attachSelector
    },
    closeDialog() {
      this.isActive = false
      this.isDanger = false
      this.persistent = false
      this.noClickAnimation = false
      this.title = ''
      this.text = ''
      this.closeText = ''
      this.confirmText = ''
      this.beforeClose = undefined
      this.beforeConfirm = undefined
      this.attachSelector = undefined
    },
    handleClose() {
      if (this.beforeClose) {
        this.beforeClose()
      }

      this.closeDialog()
    },
    handleConfirm() {
      if (this.beforeConfirm) {
        this.beforeConfirm()
      }

      this.closeDialog()
    },
  },
}
</script>

<style lang="scss">
@import '~www/themes/doctors/common/mixins';

#app[data-base-dialog] {
  @include v-app-reset;
}
</style>

<style lang="scss" scoped>
@import '~www/themes/doctors/common/vuetify-custom-reset';

.base-dialog {
  // выше SideMenu
  z-index: 10002;
}

@include vuetify-custom-reset;
</style>
