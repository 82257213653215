<template>
  <VApp data-draft-reminder>
    <VBanner
      v-model="isVisible"
      class="draft-reminder"
      :class="{ 'draft-reminder_desktop b-container mb-5': !isMobile }"
      elevation="24"
    >
      <div
        class="d-flex justify-content-between"
        :class="{ 'flex-direction-column': isMobile }"
      >
        <div
          class="d-flex"
          :class="{
            'align-items-center': !isMobile,
            'pt-2': isMobile,
          }"
        >
          <img
            class="draft-reminder__avatar"
            :class="{ 'draft-reminder__avatar_lpu': !isReviewObjectDoctor }"
            :alt="storageData.reviewObject.name"
            :src="storageData.reviewObject.avatarUrl"
          >
          <div
            class="draft-reminder_text ui-text ui-text_body-2"
            :class="{
              'ml-4': isMobile,
              'ml-6': !isMobile,
            }"
          >
            У вас остался незавершённый отзыв о {{ reviewObjectTypeText }}
            <span
              class="ui-text ui-text_subtitle-2"
              :class="{ 'ui-text_nowrap': isReviewObjectDoctor }"
            >
              {{ reviewObjectNameText }}
            </span>
            <br v-if="isMobile">Хотите дописать его и поделиться мнением с пациентами?
          </div>
        </div>
        <div class="d-flex align-items-center justify-content-end">
          <VBtn
            text
            class="px-2"
            color="text"
            @click="handleClickNotNow"
          >
            Не сейчас
          </VBtn>
          <VBtn
            text
            class="px-2 ml-2"
            :class="{ 'mr-2': !isMobile }"
            color="primary"
            :href="newRateUrl"
            @click="handleClickGoToReview"
          >
            К отзыву
          </VBtn>
        </div>
      </div>
    </VBanner>
  </VApp>
</template>

<script>
import Cookie from 'js-cookie'
import { axiosClient, createDevNotice } from 'utils'
import { differenceInHours, differenceInMinutes } from 'date-fns'
import UserStorage from 'modules/UserStorage'
import {
  STORAGE_KEY,
  HOURS_BETWEEN_DISPLAYS,
  MINUTES_BEFORE_FIRST_DISPLAY,
  MAX_NUMBER_OF_DISPLAYS,
  REVIEW_OBJECT_TYPES,
  AJAX_URL_IS_VISIBLE,
  MODULE_NAME,
} from 'components/common/DraftReminder/constants'
import ManageYandexGoals from 'components/common/mixins/ManageYandexGoals'
import { DRAFT_REMINDER_YANDEX_GOALS } from 'yandexGoals'

export default {
  name: 'DraftReminder',
  mixins: [
    ManageYandexGoals,
  ],
  data: () => ({
    isVisible: false,
    storageData: {
      reviewObject: {
        type: '',
        name: '',
        id: 0,
        avatarUrl: '',
      },
      createdAt: 0,
      lastDisplayedAt: 0,
      displaysCount: 0,
    },
    cookieUserkey: Cookie.get('userkey'),
    isMobile: window.MOBILE_VERSION,
    yaGoalsList: {
      ...DRAFT_REMINDER_YANDEX_GOALS,
    },
  }),
  computed: {
    newRateUrl() {
      const reviewObjectType = REVIEW_OBJECT_TYPES[this.storageData.reviewObject.type]

      if (!reviewObjectType) {
        return ''
      }

      return `/new/rate/${reviewObjectType}/${this.storageData.reviewObject.id}/`
    },
    isReviewObjectDoctor() {
      return this.storageData.reviewObject.type === REVIEW_OBJECT_TYPES.doctor
    },
    reviewObjectTypeText() {
      return this.isReviewObjectDoctor ? 'враче' : 'клинике'
    },
    reviewObjectNameText() {
      return `${this.storageData.reviewObject.name}${this.isReviewObjectDoctor ? '' : '.'}`
    },
  },
  watch: {
    storageData: {
      deep: true,
      handler(newStorageData) {
        try {
          UserStorage.set(STORAGE_KEY, newStorageData)
        } catch {
          UserStorage.remove(STORAGE_KEY)
        }
      },
    },
  },
  mounted() {
    if (!this.cookieUserkey) {
      return
    }

    const storageData = UserStorage.get(STORAGE_KEY)

    if (!storageData || typeof storageData !== 'object' || !storageData.reviewObject) {
      return
    }

    const currentDate = Date.now()
    const { createdAt, lastDisplayedAt, displaysCount } = storageData

    const isTooEarlyToShow = differenceInMinutes(currentDate, createdAt) < MINUTES_BEFORE_FIRST_DISPLAY
            || differenceInHours(currentDate, lastDisplayedAt) < HOURS_BETWEEN_DISPLAYS

    if (isTooEarlyToShow || displaysCount >= MAX_NUMBER_OF_DISPLAYS) {
      return
    }

    this.storageData = storageData

    const userData = {
      userkey: this.cookieUserkey,
      object_type: storageData.reviewObject.type,
      object_id: storageData.reviewObject.id,
    }

    axiosClient.post(AJAX_URL_IS_VISIBLE, userData)
      .then(({ data }) => {
        const { result: isVisible } = data

        if (isVisible) {
          this.isVisible = true

          this.sendYandexGoal('show')
        } else {
          UserStorage.remove(STORAGE_KEY)
        }

        return null
      })
      .catch(error => {
        createDevNotice({
          module: MODULE_NAME,
          method: 'axiosClient.post',
          description: `${error.message} | ${JSON.stringify(error.response?.data)}`,
        })
        UserStorage.remove(STORAGE_KEY)
      })
  },
  methods: {
    handleClickNotNow() {
      this.isVisible = false
      this.storageData.displaysCount++
      this.storageData.lastDisplayedAt = Date.now()

      this.sendYandexGoal('notNow')
    },
    handleClickGoToReview() {
      this.sendYandexGoal('goToReview')
    },
  },
}
</script>

<style lang="scss">
@import '~www/themes/doctors/common/variables';
@import '~www/themes/doctors/common/mixins';

#app[data-draft-reminder] {
  @include v-app-reset;

  .draft-reminder {
    position: fixed;
    bottom: 0;
    z-index: 201;
    background-color: $ui-kit-bg-gray-0;

    &_desktop {
      left: 50%;
      transform: translateX(-50%);

      .draft-reminder_text {
        width: 80%;
      }
    }

    &__avatar {
      width: 60px;
      height: 80px;
      object-fit: contain;
      border-radius: $border-radius-sm;
    }

    &__avatar_lpu {
      height: 60px;
    }
  }
}
</style>
