import Vue from 'components'
import vuetify from 'modules/vuetify-plugin'
import ClubCardInfo from 'components/common/Club/components/core/ClubCardInfo/ClubCardInfo.vue'

const el = document.getElementById('vue-club-card-info')

if (el) {
  new Vue({
    el,
    vuetify,
    render: h => h(ClubCardInfo),
  })
}
