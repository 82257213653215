import Vue from 'components'
import Vuetify from 'vuetify/lib/framework'
import * as directives from 'vuetify/lib/directives'
import { ru } from 'vuetify/lib/locale'
import styleVars from 'www/themes/doctors/common/variables-export.module.scss'

Vue.use(Vuetify, {
  directives,
})

const opts = {
  lang: {
    current: 'ru',
    locales: { ru },
  },
  theme: {
    options: {
      variations: false,
    },
    themes: {
      light: {
        ...styleVars,
        anchor: 'none',
      },
    },
  },
  icons: {
    iconfont: 'ui-icon',
    values: {
      next: 'ui-icon-arrow-right ui-icon_fz_smaller',
      prev: 'ui-icon-arrow-left ui-icon_fz_smaller',
      radioOn: 'ui-icon-radio-on',
      radioOff: 'ui-icon-radio-off',
      checkboxOn: 'ui-icon-checkbox-on',
      checkboxOff: 'ui-icon-checkbox-off',
      closeNotCircle: 'ui-icon-close-not-a-circle',
      emailIcon: 'ui-icon-at-sign',
      arrowDown: 'ui-icon-arrow-down',
    },
  },
}

export default new Vuetify(opts)
