/**
 * @author Кирилл Шабанов (@shabanov)
 */

import Vue from 'vue'
import { PiniaVuePlugin } from 'pinia'
import VueTheMask from 'vue-the-mask'

Vue.config.devtools = process.env.NODE_ENV === 'development'

Vue.use(VueTheMask)
Vue.use(PiniaVuePlugin)

export default Vue
