import { render, staticRenderFns } from "./SearchBar.vue?vue&type=template&id=47be14d8&scoped=true&"
import script from "./SearchBar.vue?vue&type=script&setup=true&lang=ts&"
export * from "./SearchBar.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./SearchBar.vue?vue&type=style&index=0&id=47be14d8&prod&scoped=true&lang=scss&"
import style1 from "./SearchBar.vue?vue&type=style&index=1&id=47be14d8&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "47be14d8",
  null
  
)

export default component.exports