/**
 * Решение было позаимствовано из Vuetify 3 (vuetify/lib/components/VOverlay/scrollStrategies.mjs)
 * */

const el: HTMLElement = document.documentElement
let countDisableScroll: number = 0 // При открытии модалки в другой модалке, скролл включаем только после закрытия последней

const disableScroll = () => {
  countDisableScroll++

  if (el.classList.contains('html-scroll-blocked')) {
    return
  }

  el.style.setProperty('--body-scroll-x', `${-el.scrollLeft}px`)
  el.style.setProperty('--body-scroll-y', `${-el.scrollTop}px`)
  el.classList.add('html-scroll-blocked')
}

const enableScroll = () => {
  countDisableScroll--

  if (countDisableScroll > 0) {
    return
  }

  const x = parseFloat(el.style.getPropertyValue('--body-scroll-x'))
  const y = parseFloat(el.style.getPropertyValue('--body-scroll-y'))

  el.style.removeProperty('--body-scroll-x')
  el.style.removeProperty('--body-scroll-y')
  el.classList.remove('html-scroll-blocked')
  el.scrollLeft = -x
  el.scrollTop = -y
}

const set = (isLock: boolean) => {
  if (isLock) {
    disableScroll()
  } else {
    enableScroll()
  }
}

const isDisabledScroll = () => el.classList.contains('html-scroll-blocked')

export default {
  set,
  disableScroll,
  enableScroll,
  isDisabledScroll,
}
