import { scrollLock } from 'utils'

export default {
  name: 'DialogManager',
  data() {
    return {
      // имя + уникальный id компонента
      dialogManagerName: `${this.$options.name}-${this._uid}`,
      dialogStack: [],
      dialogUseDefaultOpen: true,
    }
  },
  watch: {
    dialogStack(val) {
      if (window.history.state?.type !== 'dialog' && !val.length) {
        scrollLock.disable()
      }

      if (!this.$_isThisDialogInState()) {
        return
      }

      if (scrollLock.isEnabled() && !val.length) {
        scrollLock.disable()
        return
      }

      if (!scrollLock.isEnabled() && val.length) {
        scrollLock.enable()
      }
    },
  },
  created() {
    this.$_storedUrl = window.location.href
    this.$_closingDialog = false

    if (this.$_isThisDialogInState()) {
      this.$_openDialogsStepByStep(window.history.state.stack)
    }

    window.addEventListener('popstate', () => {
      // Некоторые браузеры при переходе назад оставляют нужный тайтл, но отображают другой
      // Просто присвоить тайтлу тайтл также не помогает, нужно затирать
      const tempTitle = document.title

      document.title = ''
      document.title = tempTitle

      if (this.$_closingDialog) {
        window.history.replaceState(window.history.state, null, this.$_storedUrl)
      }

      if (this.$_isThisDialogInState() && this.dialogStack.length !== window.history.state.stack.length) {
        this.dialogStack = window.history.state.stack
      }

      if (!this.$_isThisDialogInState() && this.dialogStack.length) {
        this.dialogStack = []
      }

      this.$_closingDialog = false
    })
  },
  methods: {
    closeDialog() {
      if (this.$_isThisDialogInState() || !this.dialogUseDefaultOpen) {
        this.$_closingDialog = true
        this.$_storedUrl = window.location.href
        this.dialogStack.pop()
        window.history.back()
      }
    },
    openDialog(dialogName) {
      this.dialogStack.push(dialogName)

      const currentState = { type: 'dialog', name: this.dialogManagerName, stack: this.dialogStack }

      window.history.pushState(currentState, null)
    },
    closeAllDialogs() {
      if (this.dialogStack.length) {
        setTimeout(() => {
          if (!this.$_closingDialog) {
            this.closeDialog()
          }

          this.closeAllDialogs()
        })
      }
    },
    isTopDialog(dialogName) {
      const topInStack = dialogName === this.dialogStack[this.dialogStack.length - 1]
      const secondFromTop = dialogName === this.dialogStack[this.dialogStack.length - 2]

      return topInStack || secondFromTop
    },
    $_isThisDialogInState() {
      const currentState = window.history.state
      const isDialogState = currentState?.type === 'dialog'

      return this.dialogUseDefaultOpen && isDialogState && currentState.name === this.dialogManagerName
    },
    $_openDialogsStepByStep(stack) {
      this.$nextTick(() => {
        if (!stack.length) {
          return
        }

        this.dialogStack.push(stack[0])
        this.$_openDialogsStepByStep(stack.slice(1))
      })
    },
  },
}
