export const LOGIN_LINKS = {
  get medtochka() {
    return `${window.MEDTOCHKA_URL}/services/sso/login/?preselected_country=${window.COUNTRY_DATA.iso}&client_id=${window.MEDTOCHKA_CLIENT_ID}&next=${encodeURIComponent(window.location.href)}`
  },
  get profileYdoc() {
    return `https://${window.location.hostname}/profile/login/?next=${encodeURIComponent(window.location.href)}`
  },
  get profile() {
    return `https://${window.SITE_SSO_AUTH}/profile/login/?next=${encodeURIComponent(window.location.href)}`
  },
}
