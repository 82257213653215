<template>
  <TheHeaderMobile
    :site-logo="siteLogo"
    :search="search"
    data-qa="the_header"
  />
</template>

<script>
import TheHeaderMobile from 'components/common/TheHeader/components/mobile/TheHeader.vue'
import { SITE_LOGO } from 'components/common/TheHeader/constants'

export default {
  name: 'TheHeader',
  components: {
    TheHeaderMobile,
  },
  props: {
    siteLogo: {
      type: Object,
      default: SITE_LOGO.pd,
    },
    search: {
      type: Object,
      default: () => ({}),
    },
  },
}
</script>
