var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app",
    { attrs: { "data-base-dialog": "" } },
    [
      _c(
        "v-dialog",
        {
          staticClass: "base-dialog",
          attrs: {
            attach: _vm.attachSelector,
            persistent: _vm.persistent,
            "max-width": _vm.maxWidth,
            "no-click-animation": _vm.noClickAnimation,
            "hide-overlay": "",
          },
          model: {
            value: _vm.isActive,
            callback: function ($$v) {
              _vm.isActive = $$v
            },
            expression: "isActive",
          },
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                { staticClass: "ui-text ui-text_h6 ui-kit-color-text" },
                [_vm._v("\n        " + _vm._s(_vm.title) + "\n      ")]
              ),
              _vm._v(" "),
              _c(
                "v-card-text",
                {
                  staticClass:
                    "ui-text ui-text_body-1 ui-kit-color-text-secondary",
                },
                [_vm._v("\n        " + _vm._s(_vm.text) + "\n      ")]
              ),
              _vm._v(" "),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _vm._v(" "),
                  _vm.closeText
                    ? _c(
                        "v-btn",
                        {
                          attrs: {
                            color: _vm.confirmText ? "text" : "primary",
                            text: "",
                          },
                          on: { click: _vm.handleClose },
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.closeText) +
                              "\n        "
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.confirmText
                    ? _c(
                        "v-btn",
                        {
                          attrs: {
                            text: "",
                            color: _vm.isDanger ? "secondary" : "primary",
                          },
                          on: { click: _vm.handleConfirm },
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.confirmText) +
                              "\n        "
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("v-overlay", { attrs: { value: _vm.isActive, "z-index": "110" } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }