var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "the-header-line" }, [
    _vm.isFFPdAnalysesCartPage
      ? _c("div", { staticClass: "the-header-line__inner" }, [
          _c(
            "div",
            { staticClass: "d-flex align-items-center justify-center" },
            [
              _c("button", {
                staticClass: "the-header-line__hamburger-btn ui-icon-menu p-3",
                attrs: {
                  type: "button",
                  "aria-hidden": "false",
                  "data-role": "none",
                  "data-qa": "the_header_hamburger_btn",
                },
                on: { click: _vm.handleClickHamburger },
              }),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass: "d-flex",
                  attrs: { href: "/", "data-qa": "the_header_logo_link" },
                },
                [
                  _c("img", {
                    staticClass: "the-header-line__logo",
                    attrs: { src: _vm.siteLogo.src, alt: _vm.siteLogo.alt },
                  }),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "d-flex align-items-center justify-center" },
            [
              _vm.isCartIconVisible
                ? _c(
                    "a",
                    {
                      staticClass:
                        "ui-icon-cart mr-4 text-decoration-none cart-icon ui-kit-color-icon-secondary",
                      attrs: { href: _vm.analysesCartUrl },
                    },
                    [
                      _c("div", {
                        staticClass: "add-point-to-cart",
                        class: { "d-none": _vm.isCartEmpty },
                      }),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.search.useRender
                ? _c(
                    "button",
                    {
                      staticClass: "the-header-line__search-btn p-3",
                      attrs: { "data-role": "none" },
                      on: { click: _vm.handleClickSearchBtn },
                    },
                    [_c("span", { staticClass: "ui-icon-search" })]
                  )
                : !_vm.isCartIconVisible
                ? _c("div", {
                    staticClass: "the-header-line__search-btn-empty",
                  })
                : _vm._e(),
            ]
          ),
        ])
      : _c("div", { staticClass: "the-header-line__inner" }, [
          _c("button", {
            staticClass: "the-header-line__hamburger-btn ui-icon-menu p-3",
            attrs: {
              type: "button",
              "aria-hidden": "false",
              "data-role": "none",
              "data-qa": "the_header_hamburger_btn",
            },
            on: { click: _vm.handleClickHamburger },
          }),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass: "d-flex",
              attrs: { href: "/", "data-qa": "the_header_logo_link" },
            },
            [
              _c("img", {
                staticClass: "the-header-line__logo",
                attrs: { src: _vm.siteLogo.src, alt: _vm.siteLogo.alt },
              }),
            ]
          ),
          _vm._v(" "),
          _vm.search.useRender
            ? _c(
                "button",
                {
                  staticClass: "the-header-line__search-btn p-3",
                  attrs: { "data-role": "none" },
                  on: { click: _vm.handleClickSearchBtn },
                },
                [_c("span", { staticClass: "ui-icon-search" })]
              )
            : _c("div", { staticClass: "the-header-line__search-btn-empty" }),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }