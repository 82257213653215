<template>
  <VDialog
    :value="value"
    fullscreen
    @input="emit('input', $event)"
  >
    <VCard>
      <div class="px-2 pt-6 pb-2">
        <VForm @submit.prevent="handleSubmitForm">
          <VTextField
            ref="textField"
            v-model="query"
            :maxlength="MAX_QUERY_LENGTH"
            type="search"
            prepend-inner-icon="ui-icon-arrow-back"
            clear-icon="ui-icon-close-not-a-circle"
            append-outer-icon="ui-icon-search"
            outlined
            autofocus
            full-width
            hide-details
            clearable
            @focus="handleFocusSearchInput"
            @click:append-outer="handleSubmitForm"
            @click:prepend-inner="emit('input', false)"
            @click:clear="query = ''"
          />
        </VForm>
      </div>
      <SearchResults
        v-if="query?.length >= MIN_QUERY_LENGTH"
        :query="query"
        :town="town"
        :is-office-ip="isOfficeIp"
      />
      <div
        v-else
        class="ui-text ui-text_body-1 ui-kit-color-text-secondary px-4 pt-8 pb-4 text-center"
      >
        Вы&nbsp;можете искать по&nbsp;фамилии врача, названию клиники и&nbsp;услуге
      </div>
    </VCard>
  </VDialog>
</template>

<script setup lang="ts">
import { onBeforeUnmount, ref } from 'vue'
import { VTextField } from 'vuetify/lib'
import SearchResults from 'components/common/SearchBar/components/SearchResults.vue'
import { MAX_QUERY_LENGTH, MIN_QUERY_LENGTH } from 'components/common/SearchBar/constants'
import type { Location } from 'components/common/SearchBar/types'
import buildSearchUrl from 'components/common/SearchBar/functions/buildSearchUrl'
import sendYaGoal from 'components/common/SearchBar/functions/sendYaGoal'

const props = defineProps<{
  value: boolean
  town: string
  searchUrl: string
  location?: Location
  isOfficeIp: boolean
}>()

const emit = defineEmits(['input'])

const query = ref('')

const textField = ref<InstanceType<typeof VTextField> & {
  isFocused: boolean
  blur:() => void
}>()

function blurOnTouchstart() {
  if (textField.value?.isFocused) {
    textField.value.blur()
  }
}

function handleSubmitForm() {
  if (query.value) {
    if (!props.isOfficeIp) {
      sendYaGoal('oldSearch_searchButton')
    }

    window.location.assign(buildSearchUrl({
      query: query.value,
      searchUrl: props.searchUrl,
      location: props.location,
    }))
  }
}

function handleFocusSearchInput() {
  if (!props.isOfficeIp) {
    sendYaGoal('search_focus')
  }
}

window.addEventListener('touchstart', blurOnTouchstart)

onBeforeUnmount(() => {
  window.removeEventListener('touchstart', blurOnTouchstart)
})
</script>
