// @ts-ignore
import Cookie from 'js-cookie'

const root = document.querySelector(':root')
let bannerNode: HTMLElement | null

function handleVisibilityCookieBanner(cookieBannerHeight: number) {
  // @ts-ignore
  root?.style.setProperty('--cookie-banner-height', `${cookieBannerHeight}px`)
}

function showBannerCookie() {
  try {
    const bannerNode = document.querySelector('.cookie-banner')

    if (!bannerNode) {
      handleVisibilityCookieBanner(0)
      return
    }

    if (!navigator.cookieEnabled) {
      bannerNode.classList.remove('d-none')
      handleVisibilityCookieBanner(bannerNode.clientHeight)

      return
    }

    const hasCookie = Cookie.get('is-cookie-banner-visible')

    if (!hasCookie) {
      bannerNode.classList.remove('d-none')
    }

    handleVisibilityCookieBanner(bannerNode.clientHeight)
  } catch {
    handleVisibilityCookieBanner(0)
  }
}

function handleClick({ target }: MouseEvent) {
  try {
    if (!navigator.cookieEnabled) {
      return
    }

    if (!(target instanceof HTMLElement)) {
      return
    }

    const button = target.closest('.cookie-banner-button')

    if (!button) {
      return
    }

    const bannerNode = button.closest('.cookie-banner')

    if (bannerNode) {
      bannerNode.classList.add('d-none')
    }

    handleVisibilityCookieBanner(0)
    Cookie.set('is-cookie-banner-visible', true, {
      expires: 365,
      sameSite: 'lax',
    })
  } catch { /* Empty */ }
}

function handleResizeCookieBanner() {
  if (!bannerNode) {
    bannerNode = document.querySelector('.cookie-banner')
  }

  const cookieBannerHeight = bannerNode ? bannerNode.clientHeight : 0

  handleVisibilityCookieBanner(cookieBannerHeight)

  if (cookieBannerHeight === 0) {
    window.removeEventListener('resize', handleResizeCookieBanner)
  }
}

document.addEventListener('DOMContentLoaded', showBannerCookie)
document.addEventListener('click', handleClick)

if (window.MOBILE_VERSION) {
  window.addEventListener('resize', handleResizeCookieBanner)
}
