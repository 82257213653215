import throttle from 'utils/decorators/throttle'

type Params = {
  el: string, // Элемент, который должен стать липким
  offset?: number // Значение, на которое должен быть смещён элементы сверху
  topLimitInvisible?: number // Значение от верха страницы, до которого элементы не будут скрываться при скролле
}

/**
 * Функция делает элемент "липким" (sticky) при прокрутке страницы.
 * */
function makeElementSticky({ el, offset = 0, topLimitInvisible = 0 }: Params) {
  const element = document.querySelector(el)

  if (!(element instanceof HTMLElement)) { return }

  let lastScroll = window.scrollY
  const elOffsetTop = element.offsetTop
  const elOffsetBottom = element.offsetTop + element.clientHeight

  // Функция для обновления состояния sticky
  const updateStickyState = () => {
    if (document.documentElement.classList.contains('html-scroll-blocked') && window.FEATURE_FLAGS.pd_fix_body_scroll) {
      return
    }

    const currentScroll = window.scrollY
    const isScrollingUp = currentScroll < lastScroll // Скролл вверх
    const isScrollingDown = currentScroll > lastScroll // Скролл вниз

    /**
     * true, значит прокрутка страницы находится в границах, где липкие элементы не должны скрываться.
     * Нужно для того, чтобы исправить проблему пустого пространства на странице, когда элемент стал липким
     * */
    const isInVisibleThreshold = currentScroll < topLimitInvisible

    const isBelowElementBottom = currentScroll > elOffsetBottom // Прокрутка страницы ниже нижней границы элемента

    // Управляем видимостью элемента
    if (isInVisibleThreshold) {
      element.classList.remove('invisible') // Показываем элемент
    } else if (isScrollingDown && isBelowElementBottom) {
      element.classList.add('invisible') // Скрываем элемент
    } else {
      element.classList.remove('invisible') // Показываем элемент
    }

    // Делаем элемент sticky, если пользователь скроллит вверх и прокрутка страницы ниже нижней границы элемента
    if (isScrollingUp && currentScroll >= elOffsetBottom) {
      element.classList.add('sticky')
    } else if (currentScroll <= elOffsetTop - offset) {
      element.classList.remove('sticky')
    }

    lastScroll = currentScroll
  }

  const updateStickyStateThrottled = throttle(updateStickyState, 50)

  window.addEventListener('scroll', () => updateStickyStateThrottled())
  updateStickyState()
}

export default makeElementSticky
