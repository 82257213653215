var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "modal d-flex flex-column",
      class: { "modal_has-footer": _vm.$slots.footer },
    },
    [
      _c(
        "div",
        {
          staticClass: "modal__header text--text",
          class: `px-${_vm.headerPaddingXStep}`,
        },
        [
          _vm.backTo || _vm.closeTo
            ? _c(
                "div",
                {
                  staticClass: "modal__header-button",
                  on: { click: _vm.handleBack },
                },
                [
                  _vm.backTo
                    ? _c("VIcon", { attrs: { color: "text" } }, [
                        _vm._v("\n        ui-icon-arrow-back\n      "),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.closeTo
                    ? _c("VIcon", { attrs: { color: "text" } }, [
                        _vm._v("\n        ui-icon-close-not-a-circle\n      "),
                      ])
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            {
              class: {
                "d-flex justify-content-between align-items-center w-100":
                  _vm.useSpaceHeader,
              },
            },
            [
              _vm.title
                ? _c("span", { class: _vm.titleClassList }, [
                    _vm._v("\n        " + _vm._s(_vm.title) + "\n      "),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm._t("titleIcon"),
            ],
            2
          ),
        ]
      ),
      _vm._v(" "),
      !_vm.hideDividers
        ? _c("VDivider", {
            staticClass: "modal__header-divider",
            class: {
              "modal__header-divider_hidden": _vm.headerExtensionVisible,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "scroll",
              rawName: "v-scroll.self",
              value: _vm.onScroll,
              expression: "onScroll",
              modifiers: { self: true },
            },
          ],
          ref: "body",
          staticClass: "modal__body",
          class: [
            _vm.classBody,
            {
              "modal__body_no-overflow-x": _vm.hideOverflowX,
              "modal__body_no-overflow-y": _vm.hideOverflowY,
              "modal__body_has-fixed-btn": _vm.hasFixedBtn,
              "modal__body_flex-basis-auto": _vm.bodyFlexBasisAuto,
              "modal__body_height-inherit": _vm.bodyHeightInherit,
            },
          ],
        },
        [
          _vm.$slots.header
            ? _c(
                "div",
                { staticClass: "modal__header-append" },
                [_vm._t("header")],
                2
              )
            : _vm._e(),
          _vm._v(" "),
          !_vm.hideDividers
            ? _c("VDivider", {
                ref: "divider",
                staticClass: "modal__header-divider",
                class: {
                  "modal__header-divider_hidden": _vm.visibleSingleDivider,
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm._t("default", null, null, { bodyHeight: _vm.bodyHeight }),
        ],
        2
      ),
      _vm._v(" "),
      _vm.$slots.footer
        ? _c("div", { staticClass: "modal__footer" }, [_vm._t("footer")], 2)
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }