<template>
  <div class="the-header-line">
    <div
      v-if="isFFPdAnalysesCartPage"
      class="the-header-line__inner"
    >
      <div class="d-flex align-items-center justify-center">
        <button
          type="button"
          class="the-header-line__hamburger-btn ui-icon-menu p-3"
          aria-hidden="false"
          data-role="none"
          data-qa="the_header_hamburger_btn"
          @click="handleClickHamburger"
        />
        <a
          href="/"
          class="d-flex"
          data-qa="the_header_logo_link"
        >
          <img
            class="the-header-line__logo"
            :src="siteLogo.src"
            :alt="siteLogo.alt"
          >
        </a>
      </div>

      <div class="d-flex align-items-center justify-center">
        <a
          v-if="isCartIconVisible"
          class="ui-icon-cart mr-4 text-decoration-none cart-icon ui-kit-color-icon-secondary"
          :href="analysesCartUrl"
        >
          <div
            class="add-point-to-cart"
            :class="{ 'd-none': isCartEmpty }"
          />
        </a>

        <button
          v-if="search.useRender"
          class="the-header-line__search-btn p-3"
          data-role="none"
          @click="handleClickSearchBtn"
        >
          <span class="ui-icon-search" />
        </button>
        <div
          v-else-if="!isCartIconVisible"
          class="the-header-line__search-btn-empty"
        />
      </div>
    </div>
    <div
      v-else
      class="the-header-line__inner"
    >
      <button
        type="button"
        class="the-header-line__hamburger-btn ui-icon-menu p-3"
        aria-hidden="false"
        data-role="none"
        data-qa="the_header_hamburger_btn"
        @click="handleClickHamburger"
      />
      <a
        href="/"
        class="d-flex"
        data-qa="the_header_logo_link"
      >
        <img
          class="the-header-line__logo"
          :src="siteLogo.src"
          :alt="siteLogo.alt"
        >
      </a>
      <button
        v-if="search.useRender"
        class="the-header-line__search-btn p-3"
        data-role="none"
        @click="handleClickSearchBtn"
      >
        <span class="ui-icon-search" />
      </button>
      <div
        v-else
        class="the-header-line__search-btn-empty"
      />
    </div>
  </div>
</template>

<script>
import { dispatchCustomEventGlobally } from 'utils'
import { EVENTS as SIDE_MENU_EVENTS } from 'components/mobile/SideMenu/constants'
import { SITE_LOGO } from 'components/common/TheHeader/constants'

export default {
  name: 'TheHeaderLine',
  props: {
    siteLogo: {
      type: Object,
      default: SITE_LOGO.pd,
    },
    search: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    isFFPdAnalysesCartPage: false,
    analysesCartUrl: '',
    isAnalysesAppointmentAvailableInTown: false,
    isCartEmpty: true,
  }),
  computed: {
    isCartIconVisible() {
      return this.isFFPdAnalysesCartPage
        && this.analysesCartUrl
        && this.isAnalysesAppointmentAvailableInTown
        && (window.USER.isRealPatient || window.USER.isAnonymous)
    },
  },
  mounted() {
    this.isFFPdAnalysesCartPage = window.FEATURE_FLAGS.pd_analyses_cart_page
    this.isCartEmpty = window.ANALYSES_CART?.isCartEmpty
    this.isAnalysesAppointmentAvailableInTown = window.ANALYSES_CART?.isAnalysesAppointmentAvailableInTown
    this.analysesCartUrl = window.ANALYSES_CART?.cartUrl
  },
  methods: {
    handleClickHamburger() {
      dispatchCustomEventGlobally(SIDE_MENU_EVENTS.open)
    },
    handleClickSearchBtn() {
      this.$emit('header:click-search-btn')
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~www/themes/doctors/common/variables';

.the-header-line {
  &__inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 0;
    background-color: $ui-kit-bg-gray-0;
  }

  &__hamburger-btn {
    border: none;
    background-color: transparent;
  }

  &__logo {
    object-fit: contain;
    width: 184px;
    height: 28px;
  }

  &__search-btn {
    display: flex;
    cursor: pointer;
    position: relative;
    font-size: 24px;
    margin: 0;
    border: none;
    background-color: transparent;
  }

  &__search-btn-empty {
    position: relative;
    width: 48px;
  }
}

:deep(.add-point-to-cart) {
  position: absolute;
  top: 0;
  right: 0;
  width: 8px;
  height: 8px;
  background-color: red;
  border-radius: 10px;
}

:deep(.cart-icon) {
  height: 24px;
  position: relative;
}
</style>
